import { useQuery } from '@apollo/react-hooks';
import { useMemo, useContext, useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceEdition } from '@confluence/change-edition';
import { getAGGClient, isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { FetchPaymentAndUpgradeStatusForProductUser } from '../graphql/PaymentAndUpgradeStatusQuery.agggraphql';

import { useBillingSourceSystem } from './useBillingSourceSystem';
import { MPU_COHORTS, MPU_QUERY, MPU_STATSIG } from './mpuHookConstants';

export const useCanUpgradeAndPayForProductUser = (skip: boolean = false) => {
	// Can view experience (can pay and upgrade, and in treatment group)

	const { cloudId, edition } = useSessionData();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { isSiteAdmin } = useContext(SPAViewContext);

	const [expConfig, fireExperimentExposure] = UNSAFE_noExposureExp(
		'more_user_products_can_upgrade_and_pay_in_product',
	);

	const cohort = (
		!isSiteAdmin ? expConfig.get('cohort', 'not-enrolled') : MPU_COHORTS.NOT_ENROLLED
	) as MPU_COHORTS;

	const { billingSourceSystem, loading: billingSourceSystemLoading } =
		useBillingSourceSystem(isSiteAdmin);

	const isEnrolled = useMemo(() => {
		if (
			(cohort === MPU_COHORTS.VARIATION || cohort === MPU_COHORTS.CONTROL) &&
			billingSourceSystem === 'CCP' &&
			edition === ConfluenceEdition.FREE &&
			!skip
		) {
			return true;
		}
		return false;
	}, [cohort, billingSourceSystem, edition, skip]);

	const {
		data,
		error,
		loading: paymentUpgradeStatusLoading,
	} = useQuery(FetchPaymentAndUpgradeStatusForProductUser, {
		client: getAGGClient(),
		variables: {
			cloudId,
			productKey: MPU_QUERY.HAMS_PRODUCT_KEY,
			offeringKey: MPU_QUERY.OFFERING_KEY,
		},
		fetchPolicy: 'cache-and-network',
		skip: !isEnrolled,
	});

	const loading = billingSourceSystemLoading || paymentUpgradeStatusLoading;

	if (error && !loading) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'failed',
				actionSubject: 'canProductUserPay',
				source: 'useCanUpgradeAndPayForProductUser',
				attributes: {
					isSiteAdmin,
					edition,
					error: error.message,
				},
			},
		}).fire();

		if (!isErrorMarkedAsHandled(error)) {
			markErrorAsHandled(error);
		}
	}

	const entitlement = data?.tenantContexts?.[0]?.entitlementInfo?.entitlement;
	const isBillingAdmin = !!entitlement?.transactionAccount?.isCurrentUserBillingAdmin;
	const canUpgradeAndPayForProductUser = !!(
		entitlement?.transactionAccount.experienceCapabilities?.addPaymentMethodV2?.isAvailableToUser &&
		entitlement?.experienceCapabilities.changeOfferingV2?.isAvailableToUser
	);

	const isEligibleForMpu = !!(canUpgradeAndPayForProductUser && isEnrolled);
	const isEligibleForMpuVariant = !!(isEligibleForMpu && cohort === MPU_COHORTS.VARIATION);
	const isEligibleForMpuControl = !!(isEligibleForMpu && cohort === MPU_COHORTS.CONTROL);

	const { standard: standardChangeOfferingLink, premium: premiumChangeOfferingLink } =
		buildChangeOfferingLinks(
			entitlement?.experienceCapabilities.changeOfferingV2?.experienceUrl,
			isEligibleForMpuVariant,
		);

	const analyticsAttributes = useMemo(
		() => ({
			mpuCohort: cohort,
			isSiteAdmin,
			isBillingAdmin: isEnrolled ? isBillingAdmin : undefined,
		}),
		[isEnrolled, isSiteAdmin, isBillingAdmin, cohort],
	);

	const fireMpuExposure = useCallback(() => {
		if (isEligibleForMpuControl || isEligibleForMpuVariant) {
			fireExperimentExposure();

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'exposed',
					actionSubject: 'feature',
					actionSubjectId: 'editionAwarenessPill',
					source: 'editionAwareness',
					attributes: {
						flagKey: MPU_STATSIG.EXPERIMENT_NAME,
						cohort,
						edition,
						...analyticsAttributes,
					},
				},
			}).fire();
		}
	}, [
		cohort,
		createAnalyticsEvent,
		edition,
		isEligibleForMpuControl,
		isEligibleForMpuVariant,
		analyticsAttributes,
		fireExperimentExposure,
	]);

	return {
		canUpgradeAndPayForProductUser,
		standardChangeOfferingLink,
		premiumChangeOfferingLink,
		isEligibleForMpu,
		isEligibleForMpuVariant,
		isEligibleForMpuControl,
		isBillingAdmin,
		error,
		loading,
		analyticsAttributes,
		fireMpuExposure,
	};
};

const buildChangeOfferingLinks = (
	url: string | undefined | null,
	addMpuQueryParam: boolean,
): { standard?: string; premium?: string } => {
	if (!url) {
		return {};
	}

	const standardUrl = new URL(url);
	addMpuQueryParam && standardUrl.searchParams.set('enableMpucup', 'true');

	const premiumUrl = new URL(standardUrl);
	premiumUrl.searchParams.set('offeringName', 'premium');

	return { standard: standardUrl.toString(), premium: premiumUrl.toString() };
};
